<template>
    <div>
        <template v-for="(filter, index) in statuses">
            <button :class="['btn btn-sm btn-outline-secondary', isSelected(filter.key, filter.value) ? 'active' : '']"
                    @click="update(filter.key, filter.value)">{{ filter.name }} ({{ getCountForStatuses(filter.value) }})
            </button>&nbsp;
        </template>
        <b-form-select v-model="selected_date" size="sm" class="pull-right" style="max-width: 200px">
            <option :value="undefined">{{$t('crud.choose_month')}}</option>
            <option v-for="date in dates" :value="date">{{ date.name }}</option>
        </b-form-select>
        <hr />
    </div>
</template>

<script>
    Array.prototype.diff = function(a) {
        return this.filter(function(i) {return a.indexOf(i) < 0;});
    };

    export default {
        props: ['value'],
        created() {
            this.filters = this.value;
            this.getStatusCount();
        },
        data() {
            return {
                filters: undefined,
                status_count: undefined,
                statuses: [{
                    key: 'status',
                    name: 'Alles',
                    value: [1,2,3,4,5,6,7],
                }, {
                    key: 'status',
                    name: this.$t('crud.orders.statuses.1'),
                    value: [1]
                }, {
                    key: 'status',
                    name: this.$t('crud.orders.statuses.2'),
                    value: [2]
                }, {
                    key: 'status',
                    name: this.$t('crud.orders.statuses.3'),
                    value: [3]
                }, {
                    key: 'status',
                    name: this.$t('crud.orders.statuses.4'),
                    value: [4]
                },{
                    key: 'status',
                    name: this.$t('crud.orders.statuses.5'),
                    value: [5]
                },{
                    key: 'status',
                    name: this.$t('crud.orders.statuses.6'),
                    value: [6]
                },{
                    key: 'status',
                    name: this.$t('crud.orders.statuses.7'),
                    value: [7]
                }],
                selected_date: undefined
            }
        },
        watch: {
            filters: {
                handler() {
                    this.$emit('input', this.filters);
                },
                immediate: true
            },
            selected_date() {
                this.update('shipping_date_to', this.selected_date ? this.selected_date.shipping_date_to : undefined);
                this.update('shipping_date_from', this.selected_date ? this.selected_date.shipping_date_from : undefined);
            }
        },
        computed: {
            dates() {
                let start = this.$moment('01-01-2019', 'DD-MM-YYYY');
                let end = this.$moment();
                let arr = [];
                while(start <= end) {
                    arr.push({
                        name: start.format('MMMM YYYY'),
                        shipping_date_from: this.$moment(start).startOf('month').format('YYYY-MM-DD'),
                        shipping_date_to: this.$moment(start).endOf('month').format('YYYY-MM-DD'),
                    });
                    start = start.add(1, 'month');
                }
                return arr;
            }
        },
        methods: {
            /**
             * Update the `status` filter with the given id.
             */
            update(key, value) {
                this.filters.forEach((filter) => {
                    if(filter.key === key) {
                        filter.value = value;
                    }
                });
            },
            isSelected(key, value) {
                for(let filter of this.filters) {
                    if(filter.key === key) {
                        return !value.diff(filter.value).length;
                    }
                }
                return false;
            },
            getCountForStatuses(statuses) {
                if(!this.status_count) {
                    // Not loaded yet.
                    return;
                }
                let count = 0;
                this.status_count.forEach((val, key) => {
                    if(statuses.indexOf(key) >= 0) {
                        count += val;
                    }
                });
                return count;
            },
            getStatusCount() {
                this.$http.get('orders/status-count').then((response) => {
                    this.status_count = response.data;
                });
            }
        }
    }
</script>
