<template>
    <crud-index-component v-bind="$data" :refresh="5000" @update-table-data="onUpdateTableData" @update-filters="onUpdateFilters" @vuetable="onVuetable">
        <template slot="pre-filters" slot-scope="scope">
            <orders-pre-filters-component v-model="scope.filters"></orders-pre-filters-component>
        </template>
        <template slot="header">
            {{ $t('crud.orders._plural') }}
        </template>
        <template slot="pre-table">
            <div class="row">
                <div class="col">
                    <div class="btn-group btn-group-sm">
                        <button v-if="!this.vuetable" class="btn btn-outline-secondary"><i class="fa fa-check"></i></button>
                        <button :class="['btn', all_checked ? 'btn-primary' : 'btn-outline-secondary']" @click="groupSelectToggle" v-else><i class="fa fa-check"></i></button>
                    <b-dropdown size="sm" variant="outline-secondary" :disabled="!this.vuetable">
                        <template slot="button-content">
                            <span class="badge badge-primary" v-if="selected_rows.length">{{ selected_rows.length }}</span> {{ $t('crud.group_actions.title') }}
                        </template>
                        <b-dropdown-item @click="groupSetStatus(1)">{{ $t('crud.group_actions.set_status_1') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupSetStatus(2)">{{ $t('crud.group_actions.set_status_2') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupSetStatus(3)">{{ $t('crud.group_actions.set_status_3') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupSetStatus(4)">{{ $t('crud.group_actions.set_status_4') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupSetStatus(5)">{{ $t('crud.group_actions.set_status_5') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupSetStatus(6)">{{ $t('crud.group_actions.set_status_6') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupSetStatus(7)">{{ $t('crud.group_actions.set_status_7') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupSetPaymentStatus(1)">{{ $t('crud.group_actions.set_payment_status_1') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupSetPaymentStatus(2)">{{ $t('crud.group_actions.set_payment_status_2') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupSetTransporter(1)">{{ $t('crud.group_actions.set_transporter_1') }}</b-dropdown-item>
                        <b-dropdown-item v-if="locale === 'de'" @click="groupSetTransporter(2)">{{ $t('crud.group_actions.set_transporter_2') }}</b-dropdown-item>
                        <b-dropdown-item v-if="locale === 'de'" @click="groupSetTransporter(3)">{{ $t('crud.group_actions.set_transporter_3') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupSetTransporter(4)">{{ $t('crud.group_actions.set_transporter_4') }}</b-dropdown-item>
                        <b-dropdown-item v-if="locale === 'nl'" @click="groupSetTransporter(5)">{{ $t('crud.group_actions.set_transporter_5') }}</b-dropdown-item>
                        <b-dropdown-item @click="groupRemove" class="text-danger">{{ $t('crud.group_actions.remove') }}</b-dropdown-item>
                    </b-dropdown>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="btn-group btn-group-sm mr-2" v-if="date">
                        <button class="btn btn-secondary" disabled>{{ date | date }}</button>
                        <router-link :to="{name: 'transport.index', query: {date: date.format('YYYY-MM-DD') }}" class="btn btn-outline-secondary"><i class="fa fa-truck"></i> Transport</router-link>
                        <button class="btn btn-outline-secondary" @click="pdf(`/pdf/overview/${date.format('YYYY-MM-DD')}`)"><i class="fa fa-file-pdf-o"></i> {{ $t('crud.pdf.overview') }}</button>
                        <button class="btn btn-outline-secondary" @click="pdf(`/pdf/schnittplan/${date.format('YYYY-MM-DD')}`)"><i class="fa fa-file-pdf-o"></i> {{ $t('crud.pdf.schnittplan') }}</button>
                        <b-dropdown size="sm" variant="outline-secondary">
                            <template slot="button-content"><i class="fa fa-file-pdf-o"></i> {{ $t('crud.pdf.delivery_list') }}</template>
                            <b-dropdown-item v-for="transporter in transporters" :key="transporter.id" @click="pdf(`/pdf/delivery-list/${transporter.id}/${date.format('YYYY-MM-DD')}`)">{{ transporter.name }}</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown size="sm" variant="outline-secondary">
                            <template slot="button-content"><i class="fa fa-file-pdf-o"></i> {{ $t('crud.pdf.pickup_list') }}</template>
                            <b-dropdown-item v-for="pickup_location in pickup_locations" :key="pickup_location.id"  @click="pdf(`/pdf/pickup-list/${pickup_location.id}/${date.format('YYYY-MM-DD')}`)">{{ pickup_location.name }}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <button class="btn btn-sm btn-outline-secondary mr-2" disabled v-else>{{ $t('crud.orders.pdf_helper_description') }}</button>
                    <div class="btn-group btn-group-sm">
                        <router-link :to="{name: 'extra.group_message', query: {date: date ? date.format('YYYY-MM-DD') : undefined}}" target="_blank" class="btn btn-sm btn-outline-secondary"><i class="fa fa-files-o"></i> {{ $t('crud.group_message.title') }}</router-link>
                        <router-link :to="'/orders?filters=' + JSON.stringify({'status': [0]})" class="btn btn-sm btn-outline-secondary" exact><i class="fa fa-file-o"></i> {{ $t('crud.draft_orders') }}</router-link>
                        <router-link :to="{name: 'orders.index', query: {filters: JSON.stringify({ deleted: 1 }) }}" exact class="btn btn-sm btn-outline-secondary"><i class="fa fa-trash-o"></i> {{ $t('crud.recycle_bin') }}</router-link>
                    </div>
                </div>
            </div>
        </template>
        <template slot="pre-actions" slot-scope="props">
            <div class="btn btn-group btn-group-sm py-0">
                <button class="btn btn-outline-secondary" v-b-tooltip.hover v-if="props.rowData.payment_status !== 1" @click="updatePaymentStatus(props.rowData, 1)" :title="$t('crud.orders.payment_statuses.1')"><i class="fa fa-credit-card"></i></button>
                <button class="btn btn-outline-secondary" v-b-tooltip.hover v-if="props.rowData.status === 1" @click="updateStatus(props.rowData, 2)" :title="$t('crud.orders.statuses.2')"><i class="fa fa-clock-o"></i></button>
                <button class="btn btn-outline-secondary" v-b-tooltip.hover v-if="props.rowData.status !== 3 && props.rowData.status !== 4" @click="updateStatus(props.rowData, 3)" :title="$t('crud.orders.statuses.3')"><i class="fa fa-ellipsis-h"></i></button>
                <button class="btn btn-outline-secondary" v-b-tooltip.hover v-if="props.rowData.status !== 4" @click="updateStatus(props.rowData, 4)" :title="$t('crud.orders.statuses.4')"><i class="fa fa-check"></i></button>
            </div>
        </template>
    </crud-index-component>
</template>

<script>
    import CrudIndexComponent from "../crud/Index";
    import OrdersPreFiltersComponent from './PreFilters';
    import {config, functions} from '../crud/mixin_config';

    export default {
        name: 'orders-index-component',
        components: {CrudIndexComponent, OrdersPreFiltersComponent},
        mixins: [functions],
        mounted() {
            this.$http.get('pickup-locations').then((response) => {
                this.pickup_locations = response.data;
            });
            this.$http.get('transporters').then((response) => {
                this.transporters = response.data;
            });
        },
        data() {
            return {
                locale: window.locale,
                crud: config('orders'),
                selected_filters: [],
                selected_rows: [],
                pickup_locations: [],
                transporters: [],
                table_data: [],
                vuetable: undefined,
                fields: [{
                    title: '#',
                    name: 'select-item'
                }, {
                    title: this.$t('crud.reference'),
                    name: 'id',
                    sortField: 'id',
                    formatter: (id) => {
                        return '#' + id;
                    }
                }, {
                    title: this.$t('crud.orders.status'),
                    name: 'order-status',
                    sortField: 'status'
                }, {
                    title: this.$t('crud.customer'),
                    name: '',
                    formatter: (record) => {
                        if(record.shipping_address) {
                            return record.shipping_address.first_name + ' ' + record.shipping_address.last_name;
                        }
                    }
                }, {
                    title: this.$t('crud.orders.address.postal_code'),
                    name: 'shipping_address.postal_code'
                }, {
                    title: this.$t('crud.orders.product'),
                    name: 'first_order_line',
                    formatter: (order_line) => {
                        if(order_line) {
                            return order_line.quantity + order_line.product.unit_name + ' ' + order_line.product.name;
                        }
                    }
                }, {
                    title: this.$t('crud.shipping_date'),
                    formatter: (record) => {
                        let str = "";
                        for(let split of record.order_splits)
                        {
                            str += this.$options.filters.date(split.shipping_date) + '<br />';
                        }

                        if(record.morning_delivery) str += '<b>' + this.$t('crud.orders.morning_delivery_short') + '</b>';
                        return str;
                    }
                }, {
                    title: this.$t('crud.orders.transporter'),
                    formatter: (record) => {
                        let first_order_split = record.order_splits[0];
                        let transporter = first_order_split ? first_order_split.transporter : null;
                        if(transporter) {
                            return '<img style="max-height: 26px" src="' + transporter.logo + '" alt="' + transporter.name + '" title="' + transporter.name + '" />';
                        }
                    },
                }, {
                    title: this.$t('crud.orders.note'),
                    name: 'order-note'
                }, {
                    title: this.$t('crud.orders.payment_status'),
                    name: 'order-payment-status'
                }, {
                    title: this.$t('crud.orders.payment_method'),
                    name: 'payment_method.name',
                    sortField: 'payment_method_id'
                }, {
                    title: this.$t('crud.orders.price'),
                    name: 'price',
                    formatter: (price) => {
                        return this.$options.filters.toCurrency(price);
                    }
                }, {
                    title: this.$t('crud.actions'),
                    name: 'slot-actions'
                }]
            };
        },
        computed: {
            /**
             * @return {Object} Moment.js
             */
            date() {
                for(let filter of this.selected_filters) {
                    if(filter.key === 'shipping_date') {
                        return filter.value ? this.$moment(filter.value, 'YYYY-MM-DD') : undefined;
                    }
                }
            },
            all_checked() {
                for(let rowData of this.table_data) {
                    if(!rowData._selected) {
                        return false;
                    }
                }
                return true;
            }
        },
        watch: {
            table_data: {
                handler() {
                    this.selected_rows = this.table_data.filter((rowData) => {
                        return rowData._selected;
                    });
                },
                deep: true
            }
        },
        methods: {
            onUpdateTableData(table_data) {
                this.table_data = table_data.slice();
            },
            onUpdateFilters(selected_filters) {
                this.selected_filters = JSON.parse(JSON.stringify(selected_filters));
            },
            onVuetable(vuetable) {
              this.vuetable = vuetable;
            },
            pdf(url) {
                // Add cookie for the duration of the browser session, because we open a PDF file directly from the API.
                // The default authentication is in the Authorization header (which is not available for GET-requests in this form).
                this.$cookie.set('token', this.$auth.token());
                window.open(this.$config.VUE_APP_API_URL + url + '?cachebust=' + Date.now(), '_blank');
            },
            groupSelectToggle()
            {
                let checked = this.all_checked;
                this.table_data.forEach((rowData) => {
                    this.$set(rowData, '_selected', !checked);
                });
            },
            groupRemove()
            {
                if(confirm(this.$t('crud.group_actions.confirm_deletion'))) {
                    Promise.all(this.selected_rows.map((rowData) => {
                        return this.remove(rowData.id, {
                            force: true,
                            notify: false
                        });
                    })).then(() => {
                        this.resetSelection();
                        this.vuetable.reload();
                        this.$toastr.s(this.$t('crud.group_actions.remove_success'));
                    }).catch((e) => {
                        this.vuetable.reload();
                        this.$toastr.e(this.$t('crud.group_actions.remove_error'));
                    });
                }
            },
            /**
             * @private
             * @return {Promise}
             */
            $_orderSetTransporter(id, transporter_id)
            {
                return this.$http.post(`orders/${id}/change-transporter`, {
                    transporter_id
                });
            },
            groupSetTransporter(transporter_id)
            {
                if(confirm(this.$t('crud.group_actions.confirm_update_transporter'))) {
                    Promise.all(this.selected_rows.map((rowData) => {
                        return this.$_orderSetTransporter(rowData.id, transporter_id);
                    })).then(() => {
                      this.resetSelection();
                      this.vuetable.reload();
                        this.$toastr.s(this.$t('crud.group_actions.update_transporter_success'));
                    }).catch(() => {
                        this.vuetable.reload();
                        this.$toastr.e(this.$t('crud.group_actions.update_transporter_error'));
                    });
                }
            },
            groupSetStatus(status)
            {
                if(confirm(this.$t('crud.group_actions.confirm_set_status'))) {
                    Promise.all(this.selected_rows.map((rowData) => {
                        return this.update(rowData.id, {
                            status: status
                        }, {
                            notify: false
                        });
                    })).then(() => {
                      this.resetSelection();
                      this.vuetable.reload();
                        this.$toastr.s(this.$t('crud.group_actions.update_success'));
                    }).catch(() => {
                        this.vuetable.reload();
                        this.$toastr.e(this.$t('crud.group_actions.update_error'));
                    });
                }
            },
            groupSetPaymentStatus(payment_status)
            {
                if(confirm(this.$t('crud.group_actions.confirm_set_payment_status'))) {
                    Promise.all(this.selected_rows.map((rowData) => {
                        return this.update(rowData.id, {
                            payment_status: payment_status
                        }, {
                            notify: false
                        });
                    })).then(() => {
                      this.resetSelection();
                      this.vuetable.reload();
                        this.$toastr.s(this.$t('crud.group_actions.update_success'));
                    }).catch(() => {
                        this.vuetable.reload();
                        this.$toastr.e(this.$t('crud.group_actions.update_error'));
                    });
                }
            },
            updateStatus(row_data, status)
            {
                return this.update(row_data.id, {
                    status: status
                }).then(() => {
                    this.vuetable.refresh();
                });
            },
            updatePaymentStatus(row_data, payment_status)
            {
                return this.update(row_data.id, {
                    payment_status: payment_status
                }).then(() => {
                    this.vuetable.refresh();
                });
            },
            resetSelection() {
              this.table_data.forEach((rowData) => {
                this.$set(rowData, '_selected', false);
              });
            }
        }
    }
</script>
